import { EpisodePage } from "./EpisodePage";

export const Episode0 = () => {
  return <EpisodePage
    episodeNumber={0}
    photoFiles={[
      "Paivi-ja-Saara.png"
    ]}
    materials={[
      {
        url: 'https://www.radiohelsinki.fi/podcastit/kehittamisen-paikkoja/',
        title: 'Kehittämisen paikkoja, haastateltavana Saara-Sisko Jäämies ja Päivi Vartiainen',
        author: 'Radio Helsinki: Helsinki Design Weekly',
      },
    ]}
   />
}

export const Episode1 = () => {
  return <EpisodePage
    episodeNumber={1}
    photoFiles={[
      "Sara-Ikavalko.png"
    ]}
    materials={[
      {
        url: 'https://www.theseus.fi/handle/10024/794376',
        title: 'Kutsuvat kaupunkitilat: julkisen tilan ihmislähtöinen suunnitteluohjeisto',
        author: 'Henna Partanen, opinnäytetyö, LAB-ammattikorkeakoulu',
      },
    ]}
   />
}

export const Episode2 = () => {
  return <EpisodePage
    episodeNumber={2}
    photoFiles={[
      "Johanna-Partti.png"
    ]}
    materials={[
      {
        url: undefined,
        title: 'Strategiana asiakaskokemus',
        author: 'Hannu Saarijärvi & Pekka Puustinen, Docendo',
      },
    ]}
   />
}

export const Episode3 = () => {
  return <EpisodePage
    episodeNumber={3}
    photoFiles={[
      "Anni-Leppanen.png"
    ]}
    materials={[
      {
        url: undefined,
        title: 'Good Services: How to design services that work',
        author: 'Lou Downe, BIS Publishers B.V.',
      },
    ]}
   />
}

export const Episode4 = () => {
  return <EpisodePage
    episodeNumber={4}
    photoFiles={[
      "Henrietta-Gronlund.png"
    ]}
    materials={[
      {
        url: 'https://www.helsinkikanava.fi/fi/web/helsinkikanava/player/event/view?eventId=190219101',
        title: 'Loppuseminaarin tallenne',
        author: 'Lähiöiden yhteisöllinen resilienssi - tutkimushanke',
      },
      {
        url: 'https://blogs.helsinki.fi/yhteisollinen-resilienssi/',
        title: 'Hankkeen blogi',
        author: undefined,
      },
      {
        url: 'https://blogs.helsinki.fi/yhteisollinen-resilienssi/files/2022/09/tutkimustulokset.pdf',
        title: 'Tutkimustulokset',
      }
    ]}
   />
}

export const Episode5 = () => {
  return <EpisodePage
    episodeNumber={5}
    photoFiles={[
      "Johanna-Sinkkonen.png"
    ]}
    materials={[
      {
        url: 'https://trepo.tuni.fi/handle/10024/102475',
        title: 'Kansalaiset kaupunkia kehittämässä',
        author: 'Bäcklund Pia, Häkli Jouni & Schulman Harry, Tampere University Press',
      },
    ]}
   />
}

export const Episode6 = () => {
  return <EpisodePage
    episodeNumber={6}
    photoFiles={[
      "Aleksi-Neuvonen.png"
    ]}
    materials={[
      {
        url: undefined,
        title: 'The Ministry for the Future',
        author: 'Kim Stanley Robinson, Orbit 2021',
      },
    ]}
   />
}

export const Episode7 = () => {
  return <EpisodePage
    episodeNumber={7}
    photoFiles={[
      "Elina-Rantanen.png"
    ]}
    materials={[
      {
        url: 'https://www.turku.fi/kaupunkitutkimus',
        title: 'Turun kaupunkitutkimusohjelma',
        author: undefined,
      },
    ]}
   />
}

export const Episode8 = () => {
  return <EpisodePage
    episodeNumber={8}
    photoFiles={[
      "Johanna-Pikkarainen.png"
    ]}
    materials={[]}
   />
}

export const Episode9 = () => {
  return <EpisodePage
    episodeNumber={9}
    photoFiles={[
      "Kaarin-Laaneots.jpg"
    ]}
    materials={[
      {
        url: 'https://annileppanen.medium.com/journeyops-palvelumuotoilun-uusi-suunta-b82da4218c3c',
        title: 'JourneyOps — palvelumuotoilun uusi suunta?',
        author: 'Anni Leppanen',
      },
    ]}
   />
}

export const Episode10 = () => {
  return <EpisodePage
    episodeNumber={10}
    photoFiles={[
      "Saara-Simonen.png"
    ]}
    materials={[
      {
        url: 'https://www.valtiolla.fi/ekosysteemit/',
        title: 'Elävänä ekosysteemeissä – arvoa yhteiskehittämisestä',
        author: 'Valtiolla.fi',
      },
    ]}
   />
}

export const Episode11 = () => {
  return <EpisodePage
    episodeNumber={11}
    photoFiles={[
      "Lotta-ja-Jaana.png"
    ]}
    materials={[
      {
        title: 'Et taida kuunnella',
        author: 'Kate Murphy',
      },
      {
        title: 'Minä näen Sinut',
        author: 'Pekka Tölli',
      },
      {
        title: 'Qualitative Researching',
        author: 'Jennifer Mason',
      },
    ]}
   />
}

export const Episode12 = () => {
  return <EpisodePage
    episodeNumber={12}
    photoFiles={[
      "Maria-Suutari-Jääskö.png",
      "Inka-Saini.png",
    ]}
    materials={[
      {
        title: 'Luomiskertomus',
        author: 'Henri Hyppönen (2023)',
      },
      {
        title: 'Soft City. Building Density for Everyday Life',
        author: 'David Sim (2019)',
      }
    ]}
   />
}

export const Episode13 = () => {
  return <EpisodePage
    episodeNumber={13}
    photoFiles={[
      "Tommi-Laitio.jpg",
    ]}
    materials={[
      {
        title: 'The Social Life of Small Urban Places',
        author: 'William H. Whyte (1980)',
        url: 'https://edisciplinas.usp.br/pluginfile.php/4412118/mod_resource/content/1/the%20social%20life%20of%20small%20urban%20spaces.pdf',
      },
    ]}
   />
}

export const Episode14 = () => {
  return <EpisodePage
    episodeNumber={14}
    photoFiles={[
      "Elina-Piskonen.png",
    ]}
    materials={[
      {
        title: 'Strategiana asiakaskokemus',
        author: 'Hannu Saarijärvi, Pekka Puustinen',
      },
      {
        title: 'Viestinnän johtaminen',
        author: 'Pipsa-Lotta Marjamäki, Jenni Vuorio',
      },
      {
        title: 'Good Services, how to design services that work',
        author: 'Lou Downe',
      },
      {
        title: 'Viestinnän palvelumuotoilu',
        author: 'Elina Piskonen ja Salla Syrman (2024, Alma Talent)',
      },
    ]}
   />
}
